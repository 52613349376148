import React, { useEffect, useState } from "react";
import ProfileImage from "./components/profileImage";
import { useLocation } from "react-router-dom";
import { useVisibility } from "../../context/VisibilityContext";
import Input from "./components/input";
import useWindowDimensions from "../../hooks/useWindowDimension";
import { useAuth } from "../../context/AuthContext";
import { Edit } from "@mui/icons-material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import APIEnd, {API_KEY} from "../../configs/apiEndPoint";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { userInfo } from "os";
import ChangePassword from "./components/ChangePassword/ChangePassword";

type Props = {};

const ChangelogPage = (props: Props) => {
  const navigate = useNavigate();

  const [editProfile, setEditProfile] = useState();

  const { partnerInformation, setPartnerInformation, referals, token, logout } =
    useAuth();

  const { width } = useWindowDimensions();

  // Phone Email FullName

  const [name, setName] = useState("");

  const [email, setEmail] = useState("");

  const [phone, setPhone] = useState("");

  const [selectedFile, setSelectedFile] = useState("");

  const handleFileChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(URL.createObjectURL(event.target.files[0]));
    }
    console.log(selectedFile);
  };

 

  const [disableUser, setDisableUser] = useState(true);

  const { isVisible, containsWord, updateCurrentUrlVal, removeCenter } =
    useVisibility();

  const centerItem = () => {
    if (window.location.href.includes("user")) {
      updateCurrentUrlVal();
      return;
    }
  };

  async function getPartnerInfo() {
    try {
      const response = await fetch(`${APIEnd}/partner/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${API_KEY}`,
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      // console.log("PARTNER INFORMATION", data);
      if (data.detail) {
      } else {
        setPartnerInformation(data);
        if (referals == null) {
        }
        // console.log(referals);
      }
      // setInterval(getReferalList, 10000)
      return partnerInformation;
    } catch (error) {
      toast.warn("ERROR GETTING INFORMATION ABOUT PARTNERS");
      logout()
    }
  }

  const onHandleEdit = () => {
    setDisableUser(!disableUser);
  };

  const location = useLocation();

  async function updateUserInformation() {
    const formData = new FormData();

    formData.append("picture", selectedFile);

    if (selectedFile) {
      const response = await fetch(`${APIEnd}/partner/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${API_KEY}`,
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
    }

    try {
      const response = await fetch(`${APIEnd}/partner/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${API_KEY}`,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          username: name.length < 3 ? partnerInformation?.username : name,
          email: !email.includes("@") ? partnerInformation?.email : email,
          phone: phone.length < 5 ? partnerInformation?.phone : phone,
        }),
      });

      const data = await response.json();

      await setPartnerInformation(data);

      if (data.full_name) {
        toast.success("Your Information Have been Updated!!!");
        setDisableUser(true);
      }

      return partnerInformation;
    } catch (error) {
      toast.error("RROR GETTING INFORMATION ABOUT PARTNERS")
      logout()
    }
  }

  useEffect(() => {
    getPartnerInfo();
    centerItem();
  }, []);

  return (
    <div style={{ display: "flex", gap: 30, flexWrap: "wrap" }}>
      <div
        style={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          backgroundColor: "white",
          padding: "8px 8px 30px 8px",
          borderRadius: 10,
          minWidth: width! < 800 ? "280px" : "400px",
          flex: width! < 800 ? 1 : "normal",
          maxHeight: 500,
          // alignSelf: 'center',
          justifySelf: "center",
        }}
      >
        <button
          title="edit info"
          style={{
            width: 35,
            height: 35,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 5,
            backgroundColor: "transparent",
            border: "2px solid green",
            borderRadius: 3,
          }}
          onClick={onHandleEdit}
        >
          <Edit style={{ color: "green", width: 20, height: 20 }} />
        </button>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: selectedFile ? 20 : -60,
          }}
        >
          <ProfileImage selectedImage={selectedFile} />
          <button
            title="Change profile Image"
            style={{
              width: 50,
              height: 30,
              right: 10,
              bottom: -30,
              cursor: disableUser ? "not-allowed" : "pointer",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              background: "transparent",
            }}
            disabled={disableUser}
          >
            <label
              htmlFor="imagePicker"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CameraAltIcon
                style={{ color: disableUser ? "grey" : "green" }}
              />
              <p style={{ color: "green" }}>{selectedFile ? "Selected" : ""}</p>
            </label>

            <input
              accept=".png, .jpeg, .jpg, .webp"
              id="imagePicker"
              type="file"
              size={12}
              color="green"
              onChange={handleFileChange}
              style={{ visibility: "hidden" }}
            ></input>
          </button>
        </div>

        {/* INPUT FIELDS */}
        <div>
          <Input
            type={"text"}
            disabled={disableUser}
            placeholder={partnerInformation?.username}
            title={"User Name"}
            key={1}
            inputValue={!disableUser ? name : partnerInformation?.username}
            onChangeFunc={(e: any) => {
              setName(e.target.value);
            }}
          />
          <Input
            type={"email"}
            disabled={disableUser}
            placeholder={partnerInformation?.email}
            title={"Email"}
            key={11}
            inputValue={!disableUser ? email : partnerInformation?.email}
            onChangeFunc={(e: any) => setEmail(e.target.value)}
          />
          <Input
            type={"text"}
            disabled={disableUser}
            placeholder={partnerInformation?.phone}
            title={"Phone Number"}
            key={3}
            inputValue={disableUser ? partnerInformation?.phone : phone}
            onChangeFunc={(e: any) => {
              setPhone(e.target.value);
            }}
          />
          {/* <Input disabled={disableUser} placeholder={"Name"} title={"Name"} key={1} />
        <Input disabled={disableUser} placeholder={"Name"} title={"Name"} key={1} /> */}
        </div>

        <div style={{ display: "flex", alignSelf: "center", marginTop: 10 }}>
          <button
            title={disableUser ? "Click Edit" : "Update Info"}
            style={{
              backgroundColor: disableUser ? "gray" : "green",
              width: 100,
              height: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
              paddingRight: 15,
              paddingLeft: 15,
              fontSize: "1.5vwS",
              cursor: disableUser ? "not-allowed" : "pointer",
            }}
            disabled={disableUser}
            onClick={updateUserInformation}
          >
            Update
          </button>
        </div>
      </div>
      <ChangePassword/>
    </div>
  );
};

export default ChangelogPage;
